import React, { useState } from "react";
import styles from "./loginform.module.css";

import {
  Alert,
  Button,
  createTheme,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  ThemeProvider,
} from "@mui/material";

import { useFormik } from "formik";
import * as yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { loginHandler } from "../../../utils/loginHandler";
import LoadingButton from "@mui/lab/LoadingButton";

export function LoginForm() {
  const [loginResult, setLoginResult] = useState({
    success: "",
    message: "",
  });
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    username: yup.string().required("login is required"),
    password: yup.string().required("password is required"),
  });
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      showPassword: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const loginResult = await loginHandler(values);
        if (!loginResult.success) {
          setLoginResult({
            success: loginResult.success,
            message: loginResult.message,
          });
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    },
  });
  const handleClickShowPassword = () => {
    setValues((formik.values.showPassword = !formik.values.showPassword));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const theme = createTheme({
    palette: {
      neutral: {
        main: "#9bdcfb",
        contrastText: "#4f5257",
      },
    },
  });

  return (
    <div className={styles.loginFormContainer}>
      <form className={styles.loginForm} onSubmit={formik.handleSubmit}>
        <FormControl className={styles.formControl}>
          <TextField
            className={styles.formField}
            sx={{ width: 1, mt: 1 }}
            id="username"
            label={"Login"}
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
          />

          <FormControl sx={{ width: 1, mt: 1 }} className={styles.formField}>
            <InputLabel
              className={styles.formField}
              htmlFor="password"
              error={formik.touched.password && Boolean(formik.errors.password)}
            >
              Password
            </InputLabel>
            <ThemeProvider theme={theme}>
              <OutlinedInput
                className={styles.formField}
                id="password"
                name="password"
                type={formik.values.showPassword ? "text" : "password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      color="neutral"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {formik.values.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </ThemeProvider>
            <FormHelperText
              error={formik.touched.password && Boolean(formik.errors.password)}
            ></FormHelperText>
          </FormControl>
          <ThemeProvider theme={theme}>
            <LoadingButton
              variant="contained"
              color="neutral"
              type="submit"
              loading={loading}
              sx={{ width: 1, borderRadius: "25px"  }}
              className={styles.btn}
            >
              SUBMIT
            </LoadingButton>
          </ThemeProvider>
        </FormControl>
      </form>
      {!loginResult.success && loginResult.message && (
        <Alert severity="error">{loginResult.message}</Alert>
      )}
    </div>
  );
}
