import React, {useRef, useState} from "react";
import styles from "./login.module.css";
import {LoginForm} from "./LoginForm"
import classNames from "classnames";
import { useClickAway } from "react-use";

export function LogIn() {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setOpen(false);
  });
  const handleClick = () => {
    setOpen(!open);
  };
  const style = classNames(styles.logIn, { [styles[`logInOpen`]]: open });
  return (
      <div ref={ref}>
        <div onClick={handleClick} className={style}>
          log in
        </div>
        {open && (
            <div>
              <LoginForm />
            </div>
        )}
      </div>
  );
}
