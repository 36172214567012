import React from "react";
import { Naming } from "./Naming";
import { Tour } from "./Tour";
import { DatingAdvice } from "./DatingAdvice";
import { LogIn } from "./LogIn";
import styles from "./header.module.css";

export function Header() {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerContent}>
        <Naming />
        <div className={styles.tourDatingGroup}>
          <Tour />
          <DatingAdvice />
        </div>

        <LogIn />
      </div>
    </div>
  );
}
