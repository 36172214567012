import axios from "axios";

export async function loginHandler(values) {
  let loginResult = {
    success: false,
    message: "",
  };
  await axios
    .post("https://test-api.zvwhome.com/api/login", {
      email: values.username,
      password: values.password,
      reference: window.location.hostname.toString().split(".")[0],
    })
    .then((res) => {
      if (res.data.STATUS) {
        //window.location.href = "http://test-landing.iseeju.com/";
        window.location.href = "https://dev.iseeju.com/newest-members";
        loginResult.success = res.data.STATUS;
      } else {
        loginResult.success = res.data.STATUS;
        loginResult.message = res.data.message;
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return loginResult;
}
