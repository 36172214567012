import React from "react";
import styles from "./info.module.css";
import { Link } from "@mui/material";

export function Info() {
  return (
    <div className={styles.infoContainer}>
      By clicking "Submit: you agree with the {" "}
      <Link className={styles.link}
        href="#"
        rel="noopener"
        color={"#ffffff"}
        aria-label="Terms and conditions link"
      >

        Terms & conditions{" "}
      </Link>
      and{" "}
      <Link className={styles.link}
        href="#"
        rel="noopener"
        color={"#ffffff"}
        aria-label="Privacy Policy link"
      >
        Privacy Policy
      </Link>
        .
    </div>
  );
}
